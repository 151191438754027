import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { withPreview } from 'gatsby-source-prismic';

import Seo from '../components/Seo';

const Album = ({ data }) => {
  const content = data.prismicAlbum.data;

  const { title } = content;

  const hasThumb = content.cover_art.thumbnails.Icon.fluid ? 'with-thumb' : '';
  const classes = `${hasThumb}`;

  return (
    <div>
      <h1 className="page-header">
        <Link to="/recordings">Recordings</Link>
      </h1>
      <div
        data-aos="fade-in"
        data-aos-delay="150"
        className="page-content"
      >
        <article className={classes}>
          {content.cover_art.thumbnails.Icon.fluid
            && (
              <figure>
                <Image
                  fluid={content.cover_art.thumbnails.Icon.fluid}
                />
              </figure>
            )
          }
          <div>
            <header>
              <h1 className="title">{title}</h1>
              <time>{content.release_date}</time>
            </header>
            <span
              dangerouslySetInnerHTML={{ __html: content.description.html }}
            />
          </div>
        </article>
      </div>
      <Seo
        slug={data.prismicAlbum.url}
        title={`Recordings: ${title}`}
        description={content.description.text}
        image={content.cover_art.thumbnails.Column.url}
      />
    </div>
  );
};

Album.propTypes = {
  data: PropTypes.object,
};

export default withPreview(Album);

export const query = graphql`
  query (
    $prismicId: ID!
  ) {
    prismicAlbum (
      prismicId: {
        eq: $prismicId
      }
    ) {
      url
      prismicId
      data {
        title
        release_date
        description {
          html
          text
        }
        cover_art {
          thumbnails {
            Column {
              url
            }
            Icon {
              fluid(
                maxWidth: 102
              ) {
                ...GatsbyPrismicImageFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
